import Cookies from 'js-cookie'

const TokenKey = 'wx-code'

export function getToken() {
  //return "tttt";
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // console.info(token);
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
